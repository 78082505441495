import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
    useNavigate, redirect,
} from "react-router-dom";
import { appState } from "../../appState";
import {server} from "../../server";
import {t, format_ts, format_ts_admin} from "../../localization";

export async function loader({ request }) {
    try {
        let prize_categories = await server.admin_get_prize_categories()
        return { prize_categories };
    } catch (e) {
        alert(t(e));
    }
    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "new") {
        return redirect(`/admin/prize_categories/new`);
    }

    return null
}


export default function AdminPrizeCategoryList() {
    const prize_categories = useLoaderData().prize_categories;

    const navigate = useNavigate();
    const handleRowClick = (prize_category) => {
        navigate(`/admin/prize_categories/${prize_category.id}`);
    }

    return (
        <>
            <section>
                <Form method="post">
                    <input type="hidden" name="action" value="new" />
                    <button type="submit">{t("button-new")}</button>
                </Form>
            </section>

            <section>
                <div className="prizeCategoriesList">
                    <table>
                        <thead>
                        <tr>
                            <td>{t("name")}</td>
                            <td>{t("price_coins")}</td>
                            <td>{t("is_vip")}</td>
                            <td>{t("is_active")}</td>
                        </tr>
                        </thead>
                        <tbody>
                        { prize_categories.map( prize_category => (
                            <tr key={prize_category.id} onClick={()=> handleRowClick(prize_category)}>
                                <td>{prize_category.name}</td>
                                <td>{prize_category.price_coins}</td>
                                <td>{prize_category.is_vip ? "○" : "×"}</td>
                                <td>{prize_category.is_active ? "○" : "×"}</td>
                            </tr>
                        )) }
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}
