import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, useNavigate,
} from "react-router-dom";
import {useState} from "react";
import {appState, logout} from "../../appState";
import {fetch_user, server} from "../../server";
import {t, utcToLocal, localToUtc} from "../../localization";

export async function loader({ params }) {
    try {
        let coupons = await server.admin_get_coupons()
        let coupon_id = parseInt(params.couponId);
        let coupon = coupons.find(coupon => coupon.id === coupon_id)

        if (coupon == undefined) {
            coupon = {
                id: "new",
                issued_coupons: 0,
            }
        }

        let mode = "ignore";

        if (coupon.manual_distribute_validity_days != null) {
            mode = "manual";

        } else if (coupon.auto_distribute_start != null) {
            mode = "auto";
        }

        return { coupon, mode };
    } catch (e) {
        alert(t(e));
    }
    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "save") {
        let coupon_info = {
            name: formEntries.name,
            description: formEntries.description,
            auto_distribute_start: formEntries.auto_distribute_start == null ? null : localToUtc(formEntries.auto_distribute_start),
            auto_distribute_end: formEntries.auto_distribute_end == null ? null : localToUtc(formEntries.auto_distribute_end),
            auto_distribute_validity_start: formEntries.auto_distribute_validity_start == null ? null : localToUtc(formEntries.auto_distribute_validity_start),
            auto_distribute_validity_end: formEntries.auto_distribute_validity_end == null ? null : localToUtc(formEntries.auto_distribute_validity_end),
            auto_distribute_vip_only: formEntries.auto_distribute_vip_only == 1,
            manual_distribute_validity_days: parseInt(formEntries.manual_distribute_validity_days),
        };

        if (formEntries.mode == "ignore" || formEntries.mode == "manual") {
            coupon_info.auto_distribute_start = null;
            coupon_info.auto_distribute_end = null;
            coupon_info.auto_distribute_validity_start = null;
            coupon_info.auto_distribute_validity_end = null;
        }

        if (formEntries.mode == "ignore" || formEntries.mode == "auto") {
            coupon_info.manual_distribute_validity_days = null;
        }

        if (params.couponId == "new") {
            try {
                const result = await server.admin_new_coupon(coupon_info)
                console.log(result);
                return redirect(`/admin/coupons`);
            } catch (e) {
                alert(t(e));
            }

        } else {
            await server.admin_edit_coupon(params.couponId, coupon_info)
            return redirect(`/admin/coupons`);
        }
    }

    return null
}

function getStartOfToday() {
    const now = new Date();
    // Set the time to midnight (00:00:00) by adjusting the hours, minutes, seconds, and milliseconds.
    now.setHours(0, 0, 0, 0);
    return now;
}

function getEndOfToday() {
    const now = new Date();
    // Create a new date object for 23:59:00 tonight
    const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 0, 0);
    return endOfToday;
}


export default function AdminCouponEdit() {
    const navigate = useNavigate();
    const { coupon, mode } = useLoaderData();
    const [ editMode, setEditMode ] = useState(mode);

    return (
        <>
            <section>
                <div className="couponEdit">
                    <h1>{coupon.name}</h1>
                    {
                        coupon.issued_count > 0 ? <p className={"warning"}>{t("warning-edit-issued-coupon").split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}</p> : ""
                    }
                    <Form method="POST">
                        <input type="hidden" name="action" value="save" />
                        <table>
                            <tbody>
                            <tr>
                                <td><label>ID</label></td>
                                <td><span>{coupon.id}</span></td>
                            </tr>
                            <tr>
                                <td><label>{t("name")}</label></td>
                                <td><span><input type="text" name="name" defaultValue={coupon.name} /></span></td>
                            </tr>
                            <tr>
                                <td><label>{t("description")}</label></td>
                                <td><textarea name="description" defaultValue={coupon.description} ></textarea></td>
                            </tr>
                            <tr>
                                <td><label>{t("distribute-mode")}</label></td>
                                <td>
                                    <input type="radio" name="mode" value="ignore" id="mode_ignore" defaultChecked={editMode == "ignore"} onChange={() => setEditMode('ignore')} /> <label htmlFor="mode_ignore">{t("mode_ignore")}</label><br />
                                    <input type="radio" name="mode" value="manual" id="mode_manual" defaultChecked={editMode == "manual"} onChange={() => setEditMode('manual')} /> <label htmlFor="mode_manual">{t("mode_manual")}</label><br />
                                    <input type="radio" name="mode" value="auto" id="mode_auto" defaultChecked={editMode == "auto"} onChange={() => setEditMode('auto')} /> <label htmlFor="mode_auto">{t("mode_auto")}</label><br />
                                </td>
                            </tr>
                            { editMode != "manual" ? "" : (
                                <tr>
                                    <td><label>{t("manual_distribute_validity_days")}</label></td>
                                    <td><span><input type="number" name="manual_distribute_validity_days" min="1" max="1095" defaultValue={coupon.manual_distribute_validity_days == null ? 90 : coupon.manual_distribute_validity_days} />　{t("days")}</span></td>
                                </tr>
                            ) }
                            { editMode != "auto" ? "" : (
                                <>
                                    <tr>
                                        <td><label>{t("auto_distribute_start")}</label></td>
                                        <td><span><input type="datetime-local" name="auto_distribute_start" min="1970-01-01T01:01"  max="3000-01-01T01:01" defaultValue={utcToLocal(coupon.auto_distribute_start == null ? getStartOfToday() : coupon.auto_distribute_start)} /></span></td>
                                    </tr>

                                    <tr>
                                        <td><label>{t("auto_distribute_end")}</label></td>
                                        <td><span><input type="datetime-local" name="auto_distribute_end" defaultValue={utcToLocal(coupon.auto_distribute_end == null ? getEndOfToday() : coupon.auto_distribute_end)} /></span></td>
                                    </tr>

                                    <tr>
                                        <td><label>{t("auto_distribute_validity_start")}</label></td>
                                        <td><span><input type="datetime-local" name="auto_distribute_validity_start" min="1970-01-01T01:01"  max="3000-01-01T01:01" defaultValue={utcToLocal(coupon.auto_distribute_validity_start == null ? getStartOfToday() : coupon.auto_distribute_validity_start)} /></span></td>
                                    </tr>

                                    <tr>
                                        <td><label>{t("auto_distribute_validity_end")}</label></td>
                                        <td><span><input type="datetime-local" name="auto_distribute_validity_end" defaultValue={utcToLocal(coupon.auto_distribute_validity_end == null ? getEndOfToday() : coupon.auto_distribute_validity_end)} /></span></td>
                                    </tr>

                                    <tr>
                                        <td><label>{t("auto_distribute_vip_only")}</label></td>
                                        <td><span><input type="checkbox" name="auto_distribute_vip_only" defaultChecked={coupon.auto_distribute_vip_only} /></span></td>
                                    </tr>
                                </>
                            ) }
                            <tr>
                                <td></td>
                                <td>
                                    <div><button type="submit">{t("button-save")}</button></div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                        >
                                            {t("button-cancel")}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Form>
                </div>
            </section>
        </>
    );
}
