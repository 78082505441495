import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, useNavigate,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import {fetch_user, server} from "../../server";
import {t} from "../../localization";

export async function loader({ request }) {
    return {  };
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "save") {
        if (formEntries.password != formEntries.password_confirm) { return }

        const user_info = {
            password: formEntries.password,
        };

        try {
            await server.edit_user(user_info)
            return redirect(`/account`);
        } catch (e) {
            alert(t(e));
            return null;
        }
    }

    return null
}



export default function AccountPassword() {
    const user = appState.user;
    const navigate = useNavigate();

    return (
        <>
            <section id="accountPassword">
                <Form method="post">
                    <input type="hidden" name="action" value="save"/>
                    <table className="form">
                        <tbody>
                        <tr>
                            <td><label htmlFor="password">{t("field-password")}</label></td>
                            <td><input id="password" name="password" type="password"/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor="password_confirm">{t("field-password-confirm")}</label></td>
                            <td><input id="password_confirm" name="password_confirm" type="password"/></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <button type="submit">{t("button-savechanges")}</button>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <button type="button" onClick={() => { navigate(-1); }} >{t("button-cancel")}</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Form>
            </section>
        </>
    );
}
