import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, useNavigate,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import {fetch_user, server} from "../../server";
import {t} from "../../localization";

export async function loader({ params }) {
    try {
        await server.fetch_common();

        if (params.missionId == "new") {
            return {
                mission: {
                    name: ""
                }
            }
        }
        let mission_id = parseInt(params.missionId);
        let mission = appState.missionLookup.get(mission_id)
        return { mission };    } catch (e) {
        alert(t(e));
    }

    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "save") {
        const mission_info = {
            number: formEntries.number,
            name: formEntries.name,
            points: parseInt(formEntries.points),
            is_single_use: formEntries.is_single_user == 1,
            limited_shopid: formEntries.limited_shopid,
            is_active: formEntries.is_active == 1,
        };

        if (params.missionId == "new") {
            try {
                const result = await server.admin_new_mission(mission_info)
                console.log(result);
                return redirect(`/admin/missions/${result.id}`);
            } catch (e) {
                alert(t(e));
            }

        } else {
            await server.admin_edit_mission(params.missionId, mission_info)
            return redirect(`/admin/missions/${params.missionId}`);
        }
    }

    return null
}



export default function AdminMissionEdit() {
    const navigate = useNavigate();
    const { mission } = useLoaderData();
    console.log(mission);
    return (
        <>
            <section>
                <div className="mission">
                    <h1>{mission.name}</h1>
                    <Form method="POST">
                        <input type="hidden" name="action" value="save" />
                        <table>
                            <tbody>
                            <tr>
                                <td><label>ID</label></td>
                                <td><span>{mission.id}</span></td>
                            </tr>
                            <tr>
                                <td><label>{t("number")}</label></td>
                                <td><span><span><input type="text" name="number" defaultValue={mission.number} /></span></span></td>
                            </tr>
                            <tr>
                                <td><label>{t("name")}</label></td>
                                <td><span><input type="text" name="name" defaultValue={mission.name} /></span></td>
                            </tr>
                            <tr>
                                <td><label>{t("points")}</label></td>
                                <td><span><input type="text" name="points" defaultValue={mission.points} /></span></td>
                            </tr>
                            <tr>
                                <td><label>{t("singleUse")}?</label></td>
                                <td><span><input type="checkbox" name="is_single_user" value="1" defaultChecked={mission.is_single_use == 1} /></span></td>
                            </tr>
                            <tr>
                                <td><label>{t("shop")}</label></td>
                                <td>
                                    <select name="limited_shopid" defaultValue={mission.limited_shopid}>
                                        <option key={"none"} value={0}>∞</option>
                                        { appState.shops.map( shop =>
                                            <option key={shop.id} value={shop.id}>{shop.name}</option>
                                        ) }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><label>{t("active")}?</label></td>
                                <td><span><input type="checkbox" name="is_active" value="1" defaultChecked={mission.is_active == 1} /></span></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <div><button type="submit">{t("button-save")}</button></div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                        >
                                            {t("button-cancel")}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Form>
                </div>
            </section>
        </>
    );
}
