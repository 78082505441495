import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import {fetch_user, server} from "../../server";
import {t} from "../../localization";

export default function NoAccountHome() {
    return (
        <>
            <section className="noaccount">
                <h2>{t("signup")}</h2>
                <p>{t("register-why-required")}</p>
                <Form method="post">
                    <input type="hidden" name="action" value="signup"/>
                    <table className="form">
                        <tbody>
                        <tr>
                            <td><label htmlFor="name">{t("field-nickname")}</label></td>
                            <td><input id="name" name="name" type="text"/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor="email">{t("field-email")}</label></td>
                            <td><input id="email" name="email" type="email"/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor="password">{t("field-password")}</label></td>
                            <td><input id="password" name="password" type="password"/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor="password_confirm">{t("field-password-confirm")}</label></td>
                            <td><input id="password_confirm" name="password_confirm" type="password"/></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><button type="submit">{t("button-register")}</button></td>
                        </tr>
                        </tbody>
                    </table>
                </Form>
            </section>

            <section  className="noaccount">
                <h2>{t("login")}</h2>
                <p>{t("login-why-required")}</p>
                <Form method="post">
                    <input type="hidden" name="action" value="login"/>
                    <table className="form">
                        <tbody>
                        <tr>
                            <td><label htmlFor="email">{t("field-email")}</label></td>
                            <td><input id="email" name="email" type="email"/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor="password">{t("field-password")}</label></td>
                            <td><input id="password" name="password" type="password"/></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <button type="submit">{t("button-login")}</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Form>
            </section>
        </>
    );
}
